/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { object, string } from 'prop-types';

const styles = (theme) => ({
    main: {
        display: 'block',
        width: '100%',
        height: 'intrinsic',
        [theme.breakpoints.down('md')]: {
            height: '10vw',
        },
    },
});

const GraphqlMobileBanner = ({ classes, bannerImage }) => {
    if (bannerImage) {
        return (
            <Grid container>
                <img className={classes.main} alt="" src={`https://cdn1.1800flowers.com/wcsstore/Flowers/images/banners/${bannerImage}?width=500&?auto=webp`} />
            </Grid>
        );
    }
    return null;
};

GraphqlMobileBanner.propTypes = {
    classes: object.isRequired,
    bannerImage: string.isRequired,
};

export default withStyles(styles)(GraphqlMobileBanner);
